body {
  background: #f5f6fa;
  color: #353b48; }

.main-container {
  width: 100% !important;
  margin-top: 20px;
  margin-left: 211px; }

.statistic-container {
  margin-left: 211px !important; }

.primary {
  background: #576490 !important;
  color: #f5f6fa !important; }

.secondary {
  background: #B2CEDE !important;
  color: #f5f6fa !important; }

@keyframes glowing {
  0% {
    box-shadow: 0 0 -10px #03a9f4; }
  20% {
    box-shadow: 0 0 10px #03a9f4; }
  40% {
    box-shadow: 0 0 20px #03a9f4; }
  60% {
    box-shadow: 0 0 20px #03a9f4; }
  80% {
    box-shadow: 0 0 10px #03a9f4; }
  100% {
    box-shadow: 0 0 -10px #03a9f4; } }

.DeviceListScreen {
  margin-bottom: 15px; }
  .DeviceListScreen ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start; }
  .DeviceListScreen li {
    margin: 20px 20px 0 0; }

.ui.cards > .cards,
.ui.card {
  min-height: 195px; }

.list-item__update {
  animation: glowing 1000ms 2; }

.window-wrapper {
  margin: 0 !important;
  padding: 0.5em 1em !important; }
  .window-wrapper__title {
    font-size: 25px;
    font-size: 1.5625rem;
    color: #334455;
    font-weight: 400;
    vertical-align: sub !important; }

.fade-enter {
  opacity: 0;
  z-index: 1; }

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 350ms ease-in-out; }

.tree-diagram g.node circle {
  fill: #fff;
  stroke: steelblue;
  stroke-width: 3px; }
  .tree-diagram g.node circle.node--stale {
    stroke: #9E9E9E; }
  .tree-diagram g.node circle.node--good {
    stroke: #4CAF50; }
  .tree-diagram g.node circle.node--ok {
    stroke: #FFEB3B; }
  .tree-diagram g.node circle.node--poor {
    stroke: #F44336; }
  .tree-diagram g.node circle.node--storefwd {
    stroke: #9C27B0; }
  .tree-diagram g.node circle.bounce {
    -webkit-animation-duration: 1.3s;
    animation-duration: 1.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: bounce;
    animation-name: bounce; }

.tree-diagram .link {
  fill: none;
  stroke: #CCCCCC;
  stroke-width: 1.5px; }

.tree-diagram .tooltip rect {
  fill: #333333;
  fill-opacity: 0.8;
  height: 40px;
  width: 185px; }

.tree-diagram .tooltip text {
  font-size: 12px;
  font-size: 0.75rem;
  fill: #FFFFFF;
  cursor: pointer; }

.statistic-group__value {
  font-weight: 200 !important;
  min-width: 150px; }

.statistic-group__label {
  font-size: 20px !important;
  font-weight: 200 !important; }

.ui.statistics .statistic > .value,
.ui.statistic > .value {
  font-size: 3rem !important; }

.alert-config__row--update {
  animation: glowing 1000ms 2; }

.device-details-table__container {
  position: relative;
  margin-bottom: 15px; }

.device-details-table__menu {
  position: absolute;
  top: -5px;
  right: -3px; }

.BulkUpdateContainer__label {
  cursor: pointer;
  font-weight: normal !important; }

.BulkUpdateContainer__input {
  display: none; }

.system-container__header {
  display: flex;
  flex-direction: row; }

.system-container__button {
  margin-left: 5px !important; }

.system-container__button2 {
  margin-left: 5px !important;
  background-color: transparent !important;
  border: transparent !important;
  cursor: pointer !important;
  display: inline !important;
  margin: 0 !important;
  padding: 2px 8px 2px 8px !important; }

.register-device-modal {
  margin-top: 0 !important;
  top: 15% !important; }
  .register-device-modal__container {
    display: flex;
    flex-direction: row; }
  .register-device-modal__form {
    flex: 0.3;
    display: flex;
    flex-direction: column; }
  .register-device-modal__list {
    flex: 0.7;
    margin-left: 10px;
    min-height: 250px; }
